html {
    width: 100%;
    /* ie fixes*/
    height: 100%;
    /*safari fixes*/
}

body {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    background-color: #F1F2F2;
    margin: 0;
    /* ie fixes*/
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

select::-ms-expand {
    display: none;
}

body #root {
    min-height: 100vh;
}

/** style for fix safari*/
* {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}

@media print {
    body {
        background-color: #fff;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body,
html {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
}

.need-input-hover {
    box-shadow: 0px 0px 20px rgba(255, 1, 13, 0.75) !important;
}

.need-button-hover {
    box-shadow: 0px 0px 1px #ff0000;
}

.button-close-modal {
    font-size: 40px;
    font-weight: 300;
    opacity: unset;
}

.content-100-height {
    min-height: calc(100vh - 90px);
}

.color-dark-grey {
    color: #97989c;
}

.pointer-cursor {
    cursor: pointer;
}

.default-button {
    background: #111da1;
    border-radius: 10px;
    color: white;
    font-family: "Roobert", "sans-serif";
    font-size: 12px;
}

.default-button:hover {
    color: white;
}

.default-button-inverse-colors {
    background: white;
    border: 2px solid #111da1;
    border-radius: 10px;
    color: #111da1;
    font-family: "Roobert", "sans-serif";
    font-size: 12px;
}

.default-button-inverse-colors:hover {
    color: #111da1;
}

.button-cancel {
    background: #efefef;
    border: 2px solid #eeeeee;
    border-radius: 10px;
    color: #393939;
    font-family: "Roobert", "sans-serif";
    font-size: 12px;
}

.button-cancel:hover {
    background: #dddddd;
}

.font-family-museo-sans {
    font-family: "Roobert", "sans-serif";
}

.font-family-arial {
    font-family: "Roobert", "sans-serif";
}

.font-size-12px {
    font-size: 12px;
}

.font-size-13px {
    font-size: 13px;
}

.font-size-16px {
    font-size: 16px;
}

.font-size-18px {
    font-size: 18px;
}

.h2-museosans {
    font-family: "Roobert", "sans-serif";
    font-size: 24px;
}

.links-temp {
    color: #4BAA8F;
}

.progress {
    height: 4px !important;
}

.progress-bar {
    background-color: #4BAA8F;
    height: 4px !important;
}

.recommended-coverage-box {
    border: 2px solid #4BAA8F;
    border-radius: 8px;
    box-sizing: border-box;
}

.sticky-element {
    position: sticky;
    z-index: 1020;
}

.tooltip-guideline .tooltip {
    margin-left: 0;
}

.tooltip {
    margin-left: 5%;
}

.tooltip.show {
    opacity: 1;
}

.tooltip .tooltip-inner {
    background-color: #ffffff;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.25);
    color: #000000;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    max-width: 200px;
    padding: 15px;
    text-align: left;
}

.tooltip.tooltip-typeahead {
    left: -295px !important;
}

.tooltip.tooltip-typeahead .tooltip-inner.tooltip-typeahead-inner {
    width: 320px;
}

.tooltip-guideline .tooltip .tooltip-inner {
    border-color: #f1513b !important;
}

.tooltip-guideline .bs-tooltip-auto[x-placement^="top"] .arrow,
.tooltip-guideline .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    left: 15px !important;
}

.tooltip-guideline .bs-tooltip-auto[x-placement^="right-end"] .arrow,
.tooltip-guideline .bs-tooltip-auto[x-placement^="right"] .arrow,
.tooltip-guideline .bs-tooltip-auto[x-placement^="left"] .arrow,
.tooltip-guideline .bs-tooltip-auto[x-placement^="left-end"] .arrow {
    top: 15px !important;
}

.tooltip-guideline .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #f1513b !important;
}

.tooltip-guideline .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #f1513b !important;
}

.tooltip-guideline .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #f1513b !important;
}

.tooltip-guideline .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #f1513b !important;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .tooltip-inner {
    border-top: 5px solid #4BAA8F;
}

.tooltip.bs-tooltip-auto[x-placement^="top"] .tooltip-inner {
    border-bottom: 5px solid #4BAA8F;
}

.tooltip.bs-tooltip-auto[x-placement^="right"] .tooltip-inner {
    border-left: 5px solid #4BAA8F;
}

.tooltip.bs-tooltip-auto[x-placement^="left"] .tooltip-inner {
    border-right: 5px solid #4BAA8F;
}

.tooltip.tooltip-typeahead.bs-tooltip-left .tooltip-inner {
    border-right: 5px solid #4BAA8F;
}

.tooltip.tooltip-typeahead.bs-tooltip-right .tooltip-inner {
    border-left: 5px solid #4BAA8F;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #4BAA8F;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #4BAA8F;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #4BAA8F;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #4BAA8F;
}

/*guidelines tooltip */
.tooltip-guideline {
    opacity: 1 !important;
}
.tooltip-guideline-inner.tooltip-inner {
    margin-top: -10px;
}
.tooltip.tooltip-guideline.bs-tooltip-bottom .tooltip-guideline-inner.tooltip-inner {
    border-top: 5px solid #f1513b;
}

.tooltip.tooltip-guideline.bs-tooltip-top .tooltip-guideline-inner.tooltip-inner {
    border-bottom: 5px solid #f1513b;
}

.tooltip.tooltip-guideline.tooltip-typeahead.bs-tooltip-left
    .tooltip-guideline-inner.tooltip-inner {
    border-right: 5px solid #f1513b;
}

.tooltip.tooltip-guideline.tooltip-typeahead.bs-tooltip-right
    .tooltip-guideline-inner.tooltip-inner {
    border-left: 5px solid #f1513b;
}

.bs-tooltip-auto .tooltip-guideline-arrow.arrow::before,
.bs-tooltip-top .tooltip-guideline-arrow.arrow::before {
    border-top-color: #f1513b;
}

.bs-tooltip-auto .tooltip-guideline-arrow.arrow::before,
.bs-tooltip-bottom .tooltip-guideline-arrow.arrow::before {
    border-bottom-color: #f1513b;
}

.bs-tooltip-auto .tooltip-guideline-arrow.arrow::before,
.bs-tooltip-left .tooltip-guideline-arrow.arrow::before {
    border-left-color: #f1513b;
}

.bs-tooltip-auto .tooltip-guideline-arrow.arrow::before,
.bs-tooltip-right .tooltip-guideline-arrow.arrow::before {
    border-right-color: #f1513b;
}
/*guidelines tooltip */

.temp-text-input {
    border: 2px solid #ecf1f7;
}

.temp-border-color {
    border-color: #4BAA8F !important;
    border-width: 2px;
}

.form-check {
    border: 2px solid #ebf0f6;
    height: calc(3.7rem + 2px);
    width: 176px;
}

.form-control {
    height: 54px;
}

.progress {
    background-color: #d5dbe2;
}

.temp-blue {
    color: #111da1;
}

.temp-mouse-pointer {
    cursor: pointer;
}

.temp-sections-links {
    background-color: unset;
    border: none;
    padding: 0;
}

.temp-sections-links:focus {
    outline: unset;
}

/*Temp for checkboxes*/

/*OLD*/
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}



.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #4BAA8F;
    border-color: #4BAA8F;
    color: white;
}

/*NEW*/
.temp-css-file.custom-control {
    align-items: center;
    border: 2px solid #ebf0f6;
    min-height: 54px;
    width: 100%;
}

.temp-css-file.custom-control-input {
    position: static;
    margin-right: 12px;
}

/*Temp for radios */

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-radius: 10px;
}

.custom-radio .custom-control-input:checked:after {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border: 12px solid rgba(255, 0, 0, 0.5);
}

.custom-radio .custom-control-label::after {
    background: no-repeat 100%/100% 100%;
    height: 16px;
    left: -22px;
    top: 2px;
    width: 12px;
}

.custom-radio .custom-control-label::before {
    top: 1px;
    left: -25px;
    width: 18px;
    height: 18px;
}

.custom-control-label {
    font-weight: 500;
    padding-left: 10px;
}
/*EndTemp for radios */

.hidden {
    display: none !important;
    pointer-events: none;
}

/* Hide the clicker in number input on browser HTML5 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

/*css for custom typeahead*/
.typeahead-select-box {
    margin-top: 6px;
    padding-top: 12px;
    background: #ffffff;
    border: 2px solid #e5eaef;
    padding-left: 0;
    z-index: 9999999;
}

.typeahead-select-box .typeahead-select-item a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 7px 0 7px 20px;
    text-transform: capitalize;
    /* font-family: "Museo Sans"; */
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #22232e;
}

.typeahead-select-box .typeahead-select-item a:hover {
    background-color: #ebf0f6;
}

.typeahead-select-box:focus {
    border: none;
}

.rbt {
    width: 100%;
}
.rbt-input-hint {
    height: 54px !important;
    line-height: 54px !important;
    padding: 1px 0;
}

/*table styles responsive css*/
div.rdt_Table {
    border-radius: 10px;
}

div.rdt_TableRow > div:not(.rdt_TableCell) {
    display: none;
}

.SectionsPercentageOfAnswer-div {
    display: flex;
    flex-direction: column;
    height: auto;
    transition: all 0.2s linear;
}

.section-choose-button {
    text-align: left;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
}

.section-choose-button.button-selected {
    color: #111da1;
    font-weight: 500;
    line-height: 140%;
    font-weight: bold;
}

.rdt_TableRow {
    cursor: pointer;
}

@media (max-width: 959px) {
    div.rdt_Table {
        background-color: transparent;
    }

    div.rdt_TableRow,
    nav.rdt_Pagination {
        background: #ffffff;
        border: 2px solid #ebf0f6;
        border-radius: 10px;
        margin-top: 10px;
    }

    div.rdt_TableRow:not(:last-of-type) {
        border-bottom: none;
    }

    div.rdt_TableHead[role="rowgroup"] {
        display: none;
    }

    div.rdt_ExpanderRow {
        background-color: transparent;
        padding: 0 2px;
    }
}

/*changes for eliminate bootstrap dependency*/
.custom-border-top {
    border-top: 1px solid #dee2e6 !important;
}

.custom-border-left {
    border-left: 1px solid #dee2e6 !important;
}

.custom-border-right {
    border-right: 1px solid #dee2e6 !important;
}

.custom-border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.custom-text-dark {
    color: #343a40 !important;
}

.custom-text-danger {
    color: #ff0000 !important;
}

.custom-pl-3,
.custom-px-3 {
    padding-left: 1rem !important;
}
.custom-pt-3,
.custom-py-3 {
    padding-top: 1rem !important;
}

.custom-pr-4,
.custom-px-4 {
    padding-right: 1.5rem !important;
}

.custom-pl-2,
.custom-px-2 {
    padding-left: 0.5rem !important;
}

.custom-pr-5,
.custom-px-5 {
    padding-right: 3rem !important;
}

.custom-h-100 {
    height: 100% !important;
}

textarea.custom-form-control,
input.rbt-input-main.custom-form-control,
input.rbt-input-main.custom-form-control:disabled,
select.custom-form-control,
div.react-date-picker__wrapper,
input.custom-form-control {
    display: block;
    width: 100%;
    height: 54px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #c2c2c2;
    background: none;
    background-clip: padding-box;
    border-radius: 0px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.react-date-picker__wrapper, div.react-date-picker {
    width: 100% !important;
    display: flex;
}

textarea.custom-form-control.on-error,
textarea.custom-form-control.on-error:focus,
input.rbt-input-main.custom-form-control.on-error,
input.rbt-input-main.custom-form-control.on-error:focus,
input.rbt-input-main.custom-form-control.on-error:disabled,
select.custom-form-control.on-error,
select.custom-form-control.on-error:focus,
select.custom-form-control.on-error:disabled,
input.custom-form-control.on-error,
input.custom-form-control.on-error:focus {
    border: 2px solid #f1513b;
    color: #f1513b;
}

input.is-typeahead.custom-form-control {
    width: 100%;
}

textarea.custom-form-control:focus,
input.rbt-input-main.custom-form-control:focus,
input.custom-form-control:focus {
    color: #242221;
    background-color: #fff;
    border-color: #242221;
    outline: 0;
    box-shadow: none;
}

textarea.custom-form-control {
    height: 100px !important;
    resize: none;
}

.subquestion-horizontal_category .custom-text-dark {
    font-weight: 500;
}

/*changes for eliminate bootstrap dependency*/

/*override date icon*/
input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url(./assets/calendar-icon.svg) no-repeat;
    margin: 0;
    height: 15px;
    background-position: center;
}

div.rbt.clearfix.open:focus {
    outline: 0;
}

.divider-full {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 100%;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #22232e !important;
    opacity: 0.4;
    font-weight: normal;
}
::-moz-placeholder {
    /* Firefox 19+ */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #22232e !important;
    opacity: 0.4;
    font-weight: normal;
}
:-ms-input-placeholder {
    /* IE 10+ */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #22232e !important;
    opacity: 0.4;
    font-weight: normal;
}
:-moz-placeholder {
    /* Firefox 18- */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #22232e !important;
    opacity: 0.4;
    font-weight: normal;
}

input[type="date"][value=""]::-webkit-datetime-edit {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #22232e !important;
    opacity: 0.4;
    font-weight: normal;
}

/*react table overrides*/

.rdt_TableCol {
    min-height: 18px;
}

.__rdt_custom_sort_icon__ svg {
    position: relative;
    bottom: 0;
}

.__rdt_custom_sort_icon__ svg path {
    transform: translate(3px, 3px);
}

.__rdt_custom_sort_icon__.desc svg {
    right: -1px;
    bottom: -1px;

}

.rbt .rbt-aux {
    position: absolute;
    top: 0;
    margin-top: 15px;
    right: 0;
    margin-right: 10px;
    z-index: 9;
}

.insurance-lines-header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    div.rdt_TableCell[role=cell] {
        display: block;
        flex-direction: column;
        -ms-flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px;
    }

    nav.rdt_Pagination {
        display: -ms-flexbox;
        background: #ffffff;
        border: 2px solid #ebf0f6;
        border-radius: 10px;
        margin-top: 10px;
        position: absolute;
        bottom: -10px;
    }
}


@media (max-width: 700px) {
    .insurance-lines-header {
        justify-content: flex-start;
    }
}

/* Removed the Powered by Agolia footer on the smaller type aheads in application*/
.ap-footer {
    display: none !important;
    height: 0px !important;
}

/*remove outline for inputs and buttons on browsers included IE and Edge*/
* {
    outline: none !important;
}

table.CalendarMonth_table {
    border-spacing: 8px;
}

div.CalendarMonthGrid_month__horizontal:nth-child(odd) > .CalendarMonth.CalendarMonth_1 {
    border-left: 2px solid #ebf0f6;
}

@media (max-width: 767px) {
    div.CalendarMonthGrid_month__horizontal:nth-child(odd) > .CalendarMonth.CalendarMonth_1 {
        border: none;
    }

    .DayPicker_transitionContainer {
        padding-bottom: 75px;
        box-sizing: content-box;
    }
}

div.CalendarMonthGrid_month__horizontal:nth-child(odd)
    > div.CalendarMonth
    > div.CalendarMonth_caption {
    margin-left: 30px;
}

td.CalendarDay__default {
    font-family: "Roobert", "sans-serif";
    font-size: 16px;
    border: none;
    color: #22232e;
    transform: scale(.8);
    will-change: transform; /* without it there are artifacts on safari with background color of cells  */
}

td.CalendarDay__default:hover {
    background-color: #4BAA8F;
    color: #ffffff;
    border: none;
}

td.CalendarDay.CalendarDay__selected {
    background-color: #4BAA8F;
    color: #ffffff;
}

button.DayPickerKeyboardShortcuts_buttonReset {
    display: none;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: transparent !important;
    color: #bfcad6 !important;
}

@media (max-width: 767px) {

    div.DayPicker {
        max-width: 100% !important;
        width: 100% !important;
    }

    div.DayPicker > div > div {
        /* min-width: 320px !important;
        max-width: 100% !important;
        width: 100% !important; */
    }

    div.CalendarMonthGrid {
        width: 100% !important;
    }

    div.DayPicker_transitionContainer {
        /* max-width: 100% !important;
        width: 100% !important;
        min-height: 100vh !important; */
    }

    div.CalendarMonthGrid_month__horizontal:nth-child(odd)
    > div.CalendarMonth
    > table.CalendarMonth_table {
        border-left: none;
        padding-left: 0;
    }

    div.DayPicker_weekHeaders > div.DayPicker_weekHeader:not(:first-child) {
        display: none;
    }
}

.guideline-disable {
    opacity: 0.5;
    background-color: red;
}

.underwriting-guideline-active div[data-name="QuestionRowContainer"]:not(.guideline-question),
.is-insurance.disabled div[data-name="QuestionRowContainer"],
.is-insurance.disabled h1,
.is-insurance.disabled h1 ~ div {
    position: relative;
    filter: grayscale(1);
    opacity: 0.4;
}

.underwriting-guideline-active
    div[data-name="QuestionRowContainer"]:not(.guideline-question)::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: no-drop;
    content: "";
}

.Toastify__toast-container {
    z-index: 100000000;
}

/** {
    border: 1px solid #f00 !important;
  }*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rbt-input-hint {
        height: 54px !important;
        line-height: 54px !important;
        padding: 0 12px !important;
        margin-top: -1px !important;
        letter-spacing: 0.01rem !important;
    }
}

.react-date-picker {
    z-index: 999999;
}

.pac-container:after{
    content:none !important;
}

/* Fixes the address shrinking when it has an error state */
input[name="company.info.address"] {
    width: 100%;
}

@media (min-width: 800px) {
    .input-no-label-showing {
        margin-top: 12px !important;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .input-no-label-showing {
        margin-top: 22px !important;
    }
}
